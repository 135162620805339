import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./auth/auth.reducer";
import { applicationFromReducer } from "./ApplicationForm/applicationForm.reducer";
import { trackAppReducer } from "./trackApplications/trackApplications.reducer";
import { axisProfileReducer } from "./axisProfile/axisProfile.reducer";
const createComposer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  applicationForm: applicationFromReducer,
  auth: authReducer,
  trackApplication: trackAppReducer,
  axisProfile:axisProfileReducer
});

export const store = createStore(
  rootReducer,
  createComposer(applyMiddleware(thunk))
);
