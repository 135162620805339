import * as TrackApplications from "./trackApplications.types";

export const trackAppInitalState = {
  trackAppLoading: false,
  trackAppSuccess: false,
  trackAppError: false,
  applicationsData: [],
};

export const trackAppReducer = (
  state = trackAppInitalState,
  { type, payload }
) => {
  switch (type) {
    //send otp cases
    case TrackApplications.GETAPPLICATIONSLOADING: {
      return {
        ...state,
        trackAppLoading: true,
      };
    }
    case TrackApplications.GETAPPLICATIONSERROR: {
      return {
        ...state,
        trackAppError: true,
        trackAppErrorMessage: payload,
        trackAppLoading: false,
      };
    }
    case TrackApplications.GETAPPLICATIONSUCCESS: {
      return {
        ...state,
        trackAppSuccess: true,
        trackAppError: false,
        trackAppLoading: false,
        applicationsData: payload,
      };
    }
    case TrackApplications.RESETTRACTAPPLICATIONSSTATUS: {
      return { ...state, trackAppSuccess: false, trackAppError: false };
    }
    default: {
      return state;
    }
  }
};
