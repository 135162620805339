export const HITFIRSTLOADING = "APPLICATIONFORM/HITFIRSTLOADING";
export const HITFIRSTSUCCESS = "APPLICATIONFORM/HITFIRSTSUCCESS";
export const HITFIRSTERROR = "APPLICATIONFORM/HITFIRSTERROR";
export const HITFIRSTSETSTATUS = "APPLICATIONFORM/HITFIRSTRESETSTAT";
//

export const HITSECONDLOADING = "APPLICATIONFORM/HITSECONDLOADING";
export const HITSECONDSUCCESS = "APPLICATIONFORM/HITSECONDSUCCESS";
export const HITSECONDSERROR = "APPLICATIONFORM/HITSECONDSERROR";
export const HITSECONDSTATUSRESET = "APPLICATIONFORM/HITSECONDSTATUSRESET";
//

export const HITTHIRDLOADING = "APPLICATIONFORM/HITTHIRDLOADING";
export const HITTHIRDERROR = "APPLICATIONFORM/HITTHIRDERROR";
export const HITTHIRDSUCCESS = "APPLICATIONFORM/HITTHIRDSUCCESS";
export const HITTHIRDSTATUSRESET = "APPLICATIONFORM/HITTHIRDSTATUS";
//

export const HITFOURTHERROR = "APPLICATIONFORM/HITFOURTHERROR";
export const HITFOURTHLOADING = "APPLICATIONFORM/HITFOURTHLOADING";
export const HITFOURTHSUCCESS = "APPLICATIONFORM/HITFOURTHSUCCESS";
export const HITFOURTHSTATUSRESET = "APPLICATIONFORM/HITFOURTHSTATUS";

// otp related types

export const SENDOTPLOADING = 'APPLICATIONFORM/SENDOTPLOADING'
export const SENDOTPERROR = 'APPLICATIONFORM/SENDOTPERROR'
export const SENDOTPSUCCESS = 'APPLICATIONFORM/SENDOTPSUCCESS'
export const SENDOTPRESETSTATUS = 'APPLICATIONFORM/SENDOTPRESETSTATUS'

//
export const GETDATALOADING = "APPLICATIONFORM/GETDATALOADING";
export const GETDATASUCCESS = "APPLICATIONFORM/GETDATASUCCESS";
export const GETDATAERROR = "APPLICATIONFORM/GETDATAERROR";

// know last step of user

export const UPDATELASTSTEP = "APPLICATIONFORM/KNOWLASTSTEPOFUSER";

//Get district

export const FETCHDISTRICT = "APPLICATIONFORM/FETCHDSTRICT";
export const FETCHDISTRICT2 = "APPLICATIONFORM/FETCHDISTRICT2";

// send otp related cases

export const FINALREQLODING = "APPLICATIONFORM/FINALREQLODING";
export const FINALREQERROR = "APPLICATIONFORM/FINALREQERROR";
export const FINALREQSUCCESS = "APPLICATIONFORM/FINALREQSUCCESS";
export const FINALREQSTATUSRESET = "APPLICATIONFORM/FINALREQSTATUSRESET";


// check customer type related cases


export const CHECKCUSTOMERTYPELOADING = 'APPLICATIONFORM/CHECKCUSTOMERTYPELOADING';
export const CHECKCUSTOMERTYPESUCCESS = 'APPLICATIONFORM/CHECKCUSTOMERTYPESUCCESS';
export const CHECKCUSTOMERTYPEERROR = 'APPLICATIONFORM/CHECKCUSTOMERTYPEERROR';
export const CHECKCUSTOMERTYPERESETSTATUS = 'APPLICATIONFORM/CHECKCUSTOMERTYPERESETSTATUS';



export const CREDITCARDLISTLAODING = 'APPLICATIONFORM/CREDITCARDLISTLAODING';
export const CREDITCARDLISTERROR = 'APPLICATIONFORM/CREDITCARDLISTERROR';
export const CREDITCARDLISTSUCCESS = 'APPLICATIONFORM/CREDITCARDLISTSUCCESS';
export const CREDITCARDLISTRESET = 'APPLICATIONFORM/CREDITCARDLISTRESET';