import * as AuthTypes from "./auth.types";

export const authInitalState = {
  sendOtpLoading: false,
  sendOtpSuccess: false,
  sendOtpError: false,
  sendOtpStatus: false,
  sendOtpErrorMessage: "",
  token: localStorage.getItem("loginToken") || "",
  encryptedToken: localStorage.getItem('__encr_ci_a_t') || '',
  loggedInUserData: {},

  verifyOtpLoading: false,
  verifyOtpSuccess: false,
  verifyOtpError: false,
  verifyOtpStatus: false,
  verifyOtpErrorMessage: "",

  logoutLoading: false,
  logoutSuccess: false,
  logoutError: false,
  logoutStatus: false,
};

export const authReducer = (state = authInitalState, { type, payload }) => {
  switch (type) {
    //send otp cases
    case AuthTypes.SENDOTPLOADING: {
      return {
        ...state,
        sendOtpLoading: true,
      };
    }
    case AuthTypes.SENDOTPSUCCESS: {
      return {
        ...state,
        sendOtpSuccess: true,
        sendOtpStatus: true,
        sendOtpLoading: false,
        otp: payload.otp,
      };
    }
    case AuthTypes.SENDOTPRESETSTATUS: {
      return {
        ...state,
        sendOtpStatus: false,
        sendOtpLoading: false,
        sendOtpError: false,
        sendOtpErrorMessage: "",
      };
    }
    case AuthTypes.SENDOTPERROR: {
      return {
        ...state,
        sendOtpError: true,
        sendOtpErrorMessage: payload,
      };
    }
    case AuthTypes.CHANGENUMBER: {
      return {
        ...state,
        sendOtpStatus: false,
        sendOtpSuccess: false,
      };
    }
    case AuthTypes.VERIFYOTPERROR: {
      return {
        ...state,
        verifyOtpError: true,
        verifyOtpErrorMessage: payload,
        verifyOtpLoading: false,
      };
    }
    case AuthTypes.VERIFYOTPLOADING: {
      return {
        ...state,
        verifyOtpLoading: true,
      };
    }
    case AuthTypes.VERIFYOTPSUCCESS: {
      localStorage.setItem("loginToken", payload.phoneNumber);
      localStorage.setItem('__encr_ci_a_t', payload.token)
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpError: false,
        verifyOtpStatus: true,
        verifyOtpSuccess: true,
        token: payload.token,
      };
    }
    case AuthTypes.VERIFYRESETSTATUS: {
      return {
        ...state,
        verifyOtpStatus: false,
      };
    }
    case AuthTypes.LOGOUTSUCCESS: {
      localStorage.removeItem("loginToken");
      localStorage.removeItem("__encr_ci_a_t");
      return {
        ...state,
        token: "",
      };
    }
    case 'UPDATETOKEN': {
      return {
        ...state,
        token: localStorage.getItem("loginToken"),
      }
    }
    default: {
      return state;
    }
  }
};
