import React from "react";
import { HStack, Spinner } from "@chakra-ui/react";

const SpinnerLoader = () => {
  return (
    <HStack
      w={"100vw"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.300"
        size="xl"
      />
    </HStack>
  );
};

export default SpinnerLoader;
