// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:wght@100;300;400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** @format */\nbody {\n  margin: 0;\n  padding: 0;\n  /* font-family: \"Poppins\", sans-serif; */\n  font-family: \"Roboto\", sans-serif;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n\n.yScrollBar::-webkit-scrollbar {\n  width: 8px;\n}\n.yScrollBar::-webkit-scrollbar-thumb {\n  background: rgb(49,130,206);\n  border-radius: 10px;\n}\n\n\n/* .swiper-pagination{\n  position: absolute;\n  top:0 !important\n} */\n\n.swiper-button-prev,.swiper-button-next{\n  position: absolute;\n  top: 30vh !important;\n  \n}\n\n.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {\nfont-size: 30px !important;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,aAAa;AAEb;EACE,SAAS;EACT,UAAU;EACV,wCAAwC;EACxC,iCAAiC;AACnC;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;AACA;EACE,2BAA2B;EAC3B,mBAAmB;AACrB;;;AAGA;;;GAGG;;AAEH;EACE,kBAAkB;EAClB,oBAAoB;;AAEtB;;AAEA;AACA,0BAA0B;AAC1B","sourcesContent":["/** @format */\n@import url(\"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:wght@100;300;400;500;700;900&display=swap\");\nbody {\n  margin: 0;\n  padding: 0;\n  /* font-family: \"Poppins\", sans-serif; */\n  font-family: \"Roboto\", sans-serif;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n}\n\n.yScrollBar::-webkit-scrollbar {\n  width: 8px;\n}\n.yScrollBar::-webkit-scrollbar-thumb {\n  background: rgb(49,130,206);\n  border-radius: 10px;\n}\n\n\n/* .swiper-pagination{\n  position: absolute;\n  top:0 !important\n} */\n\n.swiper-button-prev,.swiper-button-next{\n  position: absolute;\n  top: 30vh !important;\n  \n}\n\n.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {\nfont-size: 30px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
