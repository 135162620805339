export const SENDOTPERROR = "AUTH/SENDOTPERROR";
export const SENDOTPSUCCESS = "AUTH/SENDOTPSUCCESS";
export const SENDOTPLOADING = "AUTH/SENDOTPLOADING";
export const SENDOTPRESETSTATUS = "AUTH/SENDOTPRESETSTATUS";

export const VERIFYOTPLOADING = "AUTH/VERIFYOTPLOADING";
export const VERIFYOTPERROR = "AUTH/VERIFYOTPERROR";
export const VERIFYOTPSUCCESS = "AUTH/VERIFYOTPSUCCESS";
export const VERIFYRESETSTATUS = "AUTH/VERIFYRESETSTATUS";

export const CHANGENUMBER = "AUTH/CHANGENUMBER";

export const RESETOTP = "AUTH/RESETOTP";

export const LOGOUTSUCCESS = "AUTH/LOGOUTSUCCESS";
