/** @format */

import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
// import ApplicationForm from "../pages/ApplicationForm";
// import HomePage from "../pages/Home";
// import TrackApplicationPage from "../pages/TrackApplicationPage";
// import Login from "../pages/Login";
// import ApplicationRejectedUIPage from "../pages/ApplicationRejectedUIPage";
// import ProfilePage from "../pages/ProfilePage";
// import ApplyAxisForm from "../pages/AxisApplyForm";
// import DisplayCardList from "../components/ApplicationFormSteps/DisplayCardList";
import SpinnerLoader from "../components/SpinnerLoader";
// import My404Component from "../pages/My404Component";
// import TrackingPageForLastApplication from "../pages/TrackingPageForLastApplication";
const HomePage = lazy(() => import("../pages/Home"));
const TrackApplicationPage = lazy(() =>
  import("../pages/TrackApplicationPage")
);
const ApplyAxisForm = lazy(() => import("../pages/AxisApplyForm"));
const Login = lazy(() => import("../pages/Login"));
const ApplicationRejectedUIPage = lazy(() =>
  import("../pages/ApplicationRejectedUIPage")
);
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const My404Component = lazy(() => import("../pages/My404Component"));
const TrackingPageForLastApplication = lazy(() =>
  import("../pages/TrackingPageForLastApplication")
);
const ApplicationForm = lazy(() => import("../pages/ApplicationForm"));
const DisplayCardList = lazy(() =>
  import("../components/ApplicationFormSteps/DisplayCardList")
);

const routesList = [
  {
    path: "/",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: "/apply-axis-card",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <ApplyAxisForm />
      </Suspense>
    ),
  },
  {
    path: "/apply-axis-bank",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <ApplicationForm />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/track-last-application",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <TrackingPageForLastApplication />
      </Suspense>
    ),
  },
  {
    path: "/app-rejected-page",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <ApplicationRejectedUIPage />
      </Suspense>
    ),
  },
  {
    path: "/track-applications",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <TrackApplicationPage />
      </Suspense>
    ),
  },
  {
    path: "/profile",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <ProfilePage />
      </Suspense>
    ),
  },
  {
    path: "/display-card-list",
    element: (
      <Suspense fallback={<SpinnerLoader />}>
        <DisplayCardList />
      </Suspense>
    ),
  },
];

function AllRoutes() {
  return (
    <Routes>
      {routesList.map((ele, ind) => (
        <Route key={ind} path={ele.path} element={ele.element} />
      ))}
      <Route
        path="*"
        exact={false}
        element={
          <Suspense fallback={<SpinnerLoader />}>
            <My404Component />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default AllRoutes;
