import * as FetchProfile from "./axisProfile.types";

const initialState = {
  fetchDataLoading: false,
  fetchDataSuccess: false,
  fetchDataError: false,
  fetchDataErrorMessage: "",
  profileData: {},
  //
  updateDataLoading: false,
  updateDataErrorMessage: "",
  updateDataSuccess: false,
  updateDataError: false,
};

export const axisProfileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FetchProfile.FETCHPROFILELOADING: {
      return {
        ...state,
        fetchDataLoading: true,
      };
    }
    case FetchProfile.FETCHPROFILESUCCESS: {
      return {
        ...state,
        fetchDataSuccess: true,
        fetchDataError: false,
        fetchDataLoading: false,
        profileData: payload,
      };
    }
    case FetchProfile.FETCHPROFILEERROR: {
      return {
        ...state,
        fetchDataError: true,
        fetchDataLoading: false,
        fetchDataErrorMessage: payload,
      };
    }
    case FetchProfile.FETCHPROFILERESET: {
      return {
        ...state,
        fetchDataError: false,
        fetchDataErrorMessage: "",
        fetchDataSuccess: true,
      };
    }
    case FetchProfile.UPDATEPROFILEDATALOADING: {
      return {
        ...state,
        updateDataLoading: true,
      };
    }
    case FetchProfile.UPDATEPROFILEDATAERROR: {
      return {
        ...state,
        updateDataError: true,
        updateDataErrorMessage: payload,
      };
    }
    case FetchProfile.UPDATEPROFILEDATASUCCESS: {
      return {
        ...state,
        updateDataSuccess: true,
      };
    }
    case FetchProfile.UPDATEPROFILEDATARESET: {
      return {
        ...state,
        updateDataLoading: false,
        updateDataError: false,
        updateDataSuccess: false,
        updateDataErrorMessage: "",
      };
    }
    default: {
      return state;
    }
  }
};
